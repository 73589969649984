<script lang="ts" setup>
import { mdiCarArrowLeft, mdiCarArrowRight } from "mdi-js-es";

const props = defineProps<{
  activatorProps?: any;
  readOnlyMobileValue?: any;
  timeOptions: string[];
}>();

const timeStart = defineModel<string>("start");
const timeEnd = defineModel<string>("end");
</script>

<template>
  <div class="flex flex-1 flex-col gap-4 lg:hidden" :class="{ 'rangepicker-compact': timeOptions.length > 0 }">
    <div class="flex">
      <VTextField id="arrival_day_2" readonly :label="$t('fields.arrival')" :modelValue="readOnlyMobileValue.start" v-bind="activatorProps">
        <template #prepend-inner>
          <VIcon start :icon="mdiCarArrowLeft" color="gray"></VIcon>
        </template>
      </VTextField>
      <VSelect id="arrival_time" v-model="timeStart" v-if="timeOptions.length > 0" :items="timeOptions" class="flex-1 min-w-[110px]" />
    </div>

    <div class="flex">
      <VTextField id="departure_day_2" readonly :label="$t('fields.departure')" :modelValue="readOnlyMobileValue.end" v-bind="activatorProps">
        <template #prepend-inner>
          <VIcon start :icon="mdiCarArrowRight" color="gray"></VIcon>
        </template>
      </VTextField>
      <VSelect id="departure_time" v-model="timeEnd" v-if="timeOptions.length > 0" :items="timeOptions" class="flex-1 min-w-[110px]" />
    </div>
  </div>
</template>
